<template>
  <div>
    <img
      class="faceBack"
      src="@/assets/img/faceBack.png"
      alt=""
      @click="back"
    />
    <img class="img1" src="@/assets/img/renliancaiji_bg1.png" alt="" />
    <div class="link">
      <div>Link</div>
      <div>{{ linkURL }}</div>
      <div @click="copyToClipboard">Copy</div>
    </div>
    <img class="img2" src="@/assets/img/renliancaiji_bg2.png" alt="" />
    <button @click="getAuthInfo">Saya telah menyelesaikan koleksinyai</button>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
import request from '@/utils/request'
import store from '@/store'
import { isIOS } from '@/utils/detect.js'
import CryptoJS from 'crypto-js'
Vue.use(Toast)
export default {
  data () {
    return {
      linkURL: ''
    }
  },
  created () {
    let userBKInfo = {}
    const { userIdBK, tokenBK, versionBK, deviceNoBK } = store.state.userBKInfo
    if (userIdBK) {
      userBKInfo = {
        usdrerID: userIdBK,
        usdrerToken: tokenBK
      }
    }
    const appType = isIOS ? 2 : 1
    const data = {
      apdrpType: appType,
      apdrpVersion: versionBK,
      dedrviceNo: deviceNoBK || sessionStorage.getItem('enhedensdige'),
      loginTerminal: sessionStorage.getItem('enhedensdige') ? 2 : 1,
      ...userBKInfo
    }
    const key = 'ciphertext'
    const jsonString = JSON.stringify(data)
    const encrypted = CryptoJS.AES.encrypt(jsonString, key).toString()
    const baseURL = CryptoJS.AES.encrypt(process.env.VUE_APP_API_ROOT, key).toString()
    this.linkURL = `https://www.cashcash.cc/faceAuthentication?data=${encodeURIComponent(encrypted)}&url=${encodeURIComponent(baseURL)}`
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    copyToClipboard () {
      var textarea = document.createElement('textarea')
      textarea.style.position = 'fixed'
      textarea.style.opacity = 0
      textarea.value = this.linkURL
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      Toast('Berhasil copy')
    },
    getAuthInfo () {
      request.post('audrthIdentityPage')
        .then(res => {
          const { podrrtraitStatus } = res.audrthList
          if (podrrtraitStatus === 1) {
            Toast('Pengumpulan berhasil.')
            this.$router.go(-1)
          } else {
            Toast('Hasil pengumpulan wajah tidak dikenali, silakan mengumpulkan foto wajah di sini! ')
          }
        })
    }
  }
}
</script>

<style scoped lang='less'>
.faceBack {
  position: absolute;
  top: 40px;
  left: 30px;
  height: 48px;
}
.img1 {
  height: 516px;
}
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  div:nth-child(1) {
    width: 88px;
    height: 55px;
    background: #f3f3f3;
    border-radius: 4px 4px 4px 4px;
    font-weight: bold;
    font-size: 28px;
    color: #7e7e7e;
    line-height: 55px;
    text-align: center;
  }
  div:nth-child(2) {
    width: 401px;
    height: 50px;
    padding: 0 12px;
    background: #f3f3f3;
    border-radius: 4px 4px 4px 4px;
    font-weight: 500;
    font-size: 24px;
    color: #81cf70;
    line-height: 50px;
    text-align: center;
    // 超出显示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div:nth-child(3) {
    width: 101px;
    height: 55px;
    background: #81cf70;
    border-radius: 4px 4px 4px 4px;
    font-weight: bold;
    font-size: 28px;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
  }
}
.img2 {
  height: 1992px;
}
button {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 651px;
  height: 93px;
  background: #81cf70;
  box-shadow: 0px 0px 26px 0px rgba(38, 214, 0, 0.34),
    inset 0px 0px 13px 0px #ffffff;
  border-radius: 8px 8px 8px 8px;
  font-weight: 500;
  font-size: 32px;
  color: #ffffff;
  margin: 0 auto;
}
</style>
